<template>
    <div class="history_wwrapper">
        <div class="row">
            <div class="col-12">
                <div class="page_title">
                    <h3>{{ $t('chat_history') }}</h3>
                </div>
            </div>
        </div>
        <div class="main_block col-lg-8">

            <div class="chat_header">
                <div class="row">
                    <div class="col-12">
                        <div class="main_block__search">
                            <div class="search-input-wrapper mb-4">
                                <SearchSvg class="position-absolute search-icon"/>
                                <input class="search-form-control" v-model="q" :placeholder="$t('search')"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div @scroll="handleScroll" class="main_block__scroller noscrollbar" ref="messagewrapper">
                <div class="chat_body" v-for="(item, index) in chats" :key="index + '-' + item.id + '-chat'">
                    <div class="row">
                        <div class="col-12 text-center">
                            <div class="main_block__lessonTitle d-flex align-items-center justify-content-center">
                                <div class="lesson_title_wrapper">
                                    <p class="mb-0">{{ item.material ? item.material.title : '-' }}, {{ item.start | moment('DD MMMM YYYY')}}</p>
                                </div>
                                <span class="line"></span>
                            </div>
                        </div>
                    </div>
                    <div class="main_block__messages" v-for="(message, index) in item.messages"
                         :id="'message-'+message.id"
                         :ref="'message-'+message.id"
                         :key="index + 'mess'">
                        <div class="row">
                            <div class="col-12">
                                <div class="teacher_block text-left d-flex" v-if="message.author_id !== $store.state.user.id">
                                    <div class="teacher_avatar mr-2">
                                        <LetterIcon v-if="message.author.id !== $store.state.user.id"
                                                    :obj="message.author"
                                                    :title="message.author.name"
                                                    class="student-icon icon-chat"/>
                                    </div>
                                    <div class="message_wrapper">
                                        <div class="message_block">
                                            <span class="teacher_name">{{message.author.name}}</span><br>
                                            <div class="d-flex justify-content-between align-items-end">
                                                <span class="message">{{message.message}}</span>
                                                <span class="message_time ml-3">{{ message.local_date | moment('HH:mm') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="student_block text-right" v-if="message.author.id === $store.state.user.id">
                                    <div class="message_wrapper_student">
                                        <div class="message_block">
                                            <div class="d-flex justify-content-between align-items-end">
                                                <span class="message">{{message.message}}</span>
                                                <span class="message_time ml-3">{{ message.local_date | moment('HH:mm') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {ChatService} from "@/services/api.service";
    import LetterIcon from '@/views/parts/general/LetterIcon'
    import SearchSvg from '@/assets/svg/search.svg'

    export default {
        name: "ChatHistory",
        components: {
            // StudentSearch,
            SearchSvg,
            LetterIcon
        },
        data() {
            return {
                chats: [],
                loading: false,
                q: '',
                more: true,
                timer: null
            }
        },
        mounted(){
            this.load()
        },
        methods: {
            load () {
                let params = {
                    oldest_message_id: this.chats.length > 0 ? this.chats[0].messages[0].id : null,
                    search: this.q
                }
                ChatService.getStudentChat(params).then((res) => {
                    let d = res.data.data
                    if(d.length === 0) {
                        this.more = false
                        return
                    }
                    this.chats = d.concat(this.chats)
                    let el = null
                    if(d.length > 0) {
                        let last = d[(d.length - 1)]
                        if(last.messages.length > 0) {
                            el = last.messages[(last.messages.length - 1)].id
                        }
                    }
                    this.scrollBottom(el)
                }).catch(err => {
                    console.log(err)
                })
            },
            handleScroll: function(el) {
                if(el.target.scrollTop < 1) { // scrolled to top
                    if(this.more) {
                        this.load()
                    }
                }
            },
            scrollBottom(element = null) {
                setTimeout(() => {
                    if(element && this.$refs['message-'+element][0]) {
                        this.$refs.messagewrapper.scrollTop = this.$refs['message-'+element][0].offsetTop
                    } else {
                        this.$refs.messagewrapper.scrollTop = this.$refs.messagewrapper.scrollHeight
                    }

                }, 50)
            },
        },
        watch: {
            q() {
                if(this.timer) {
                    clearTimeout(this.timer)
                }
                this.timer = setTimeout(() => {
                    this.chats = []
                    this.more = true
                    this.load()
                }, 250)
            }
        },
        metaInfo() {
            return {
                title: this.$t('chat_history'),
            }
        },
    }
</script>

<style lang="scss" scoped>
    .page_title {
        h3 {
            font-weight: 700;
            font-size: 26px;
        }
    }

    .chat_body {
        padding-bottom: 50px;
    }

    .main_block {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background: #FFFFFF;
        border: 1px solid #DADFEB;
        margin: 32px auto 0;

        &__search {
            padding: 16px 24px 0;
        }

        &__lessonTitle {
            .lesson_title_wrapper {
                position: relative;
                z-index: 1;
                max-width: 85%;
            }

            p {
                font-weight: 500;
                font-size: 13px;
                color: #9B9FA9;
                background: #FFFFFF;
                padding: 10px 16px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .line {
                &:before {
                    top: 0;
                    right: 0;
                    margin: auto;
                    left: 0;
                    bottom: 0;
                    position: absolute;
                    content: '';
                    width: 92%;
                    height: 1px;
                    z-index: 0;
                    background: #EDEFF4;
                }
            }
        }

        &__scroller {
            overflow-y: scroll;
            height: calc(100vh - 310px);
        }

        &__messages {
            padding: 16px 24px 6px;

            .teacher_avatar {
                img {
                    max-width: 36px;
                }
            }

            .message_block {
                text-align: left;
            }

            .teacher_block {
                max-width: 485px;
                word-break: break-word;
                margin-bottom: 6px;
            }

            .student_block {
                margin-left: auto;
            }

            .message_wrapper {
                width: auto;
                border: 1px solid #F0F0F4;
                border-radius: 12px 12px 12px 0;
                padding: 3px 10px;
            }

            .message_wrapper_student {
                display: inline-block;
                width: auto;
                background: #F2F3F5;
                border-radius: 12px 12px 0 12px;
                padding: 3px 10px;
            }

            span.teacher_name {
                font-size: 12px;
                font-weight: 600;
                color: #9B9FA9;
            }

            span.message {
                font-weight: 500;
                font-size: 14px;
            }

            span.message_time {
                white-space: nowrap;
                font-weight: 400;
                font-size: 10px;
                color: #9B9FA9;
            }
        }

    }
</style>
